/* eslint-disable jsx-a11y/control-has-associated-label */
import "./styles.scss"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"

import { getInvestmentName } from "utils/functions"

import { useLocale } from "context/locale"

const PostContent = ({ data }) => {
  const { t } = useLocale()
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const gallery = data?.data?.acfPost?.gallery

  console.log(data?.data?.acfPost)

  return (
    <section className="post-content">
      <div className="container-fluid">
        <div className="post-content__wrapper">
          <div className="row">
            <div className="col-md-2">
              <p className="post-content__date">
                {data?.data?.date.split("T")[0].split("-").reverse().join(".")}
              </p>
            </div>
            <div className="col-md-9">
              <h2>{data.title}</h2>
              <div className="post-content__label">
                {t(
                  getInvestmentName(data?.data?.acfPost?.investmentRelation) !==
                    null
                    ? getInvestmentName(data?.data?.acfPost?.investmentRelation)
                    : data?.data?.categories?.nodes[0]?.name
                )}
              </div>
              {data?.data?.excerpt && (
                <div
                  className="post-content__excerpt"
                  dangerouslySetInnerHTML={{ __html: data?.data?.excerpt }}
                />
              )}
              {data?.data?.featuredImage?.node?.localFile?.publicURL && (
                <div
                  className="post-content__image"
                  style={{
                    backgroundImage: `url('${data?.data?.featuredImage?.node?.localFile?.publicURL}')`,
                  }}
                />
              )}
              <div
                className="post-content__content"
                dangerouslySetInnerHTML={{ __html: data?.data?.content }}
              />
              {gallery && (
                <div className="post-content__gallery">
                  <div className="row">
                    {data?.data?.acfPost?.gallery?.map((item, index) => (
                      <div className="col-md-4" key={index}>
                        <button
                          className="post-content__gallery-wrapper"
                          onClick={e => {
                            e.preventDefault()
                            setIsLightboxOpen(true)
                            setPhotoIndex(index)
                          }}
                        >
                          <div
                            className="post-content__gallery-item"
                            style={{
                              backgroundImage: `url('${item?.localFile?.publicURL}')`,
                            }}
                          ></div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]?.localFile?.publicURL}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </section>
  )
}

export default PostContent
