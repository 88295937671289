export const getInvestmentName = investment => {
  switch (investment) {
    case "nove_slichowice":
      return "Nove Ślichowice"
    case "sky_trust":
      return "Sky Trust"
    case "biura_sky_trust":
      return "Biura Sky Trust"
    case "stacja_kopernik":
      return "Stacja Kopernik"
    case "rezydencja_sikorskiego":
      return "Rezydencja Sikorskiego"
    case "rezydencja_swierkowa":
      return "Rezydencja Świerkowa"
    case "rezydencja_centrum":
      return "Rezydencja Centrum"
    case "krakowska_rogatka":
      return "Krakowska Rogatka"
    case "panorama_struga":
      return "Panorama Struga"
    case "plaza_city":
      return "Plaza City"
    case "ultradom":
      return "Ultradom"
    default:
      return null
  }
}
