import "./styles.scss"

import React from "react"
import Select from "react-select"

import { useLocale } from "context/locale"

const Header = ({ categories, cat, setCat }) => {
  const { t } = useLocale()

  const selectStyles = {
    input: provided => ({
      ...provided,
      color: "#000",
    }),
    container: provided => ({
      ...provided,
      width: "100%",
      background: "transparent",
      backgroundColor: "transparent",
      outline: "none",
      border: "1px solid #dedede",
      borderRadius: 30,
      padding: "6px 10px",
      fontWeight: 700,
      color: "#000",
    }),
    control: provided => ({
      ...provided,
      background: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff",
      right: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: state.isSelected ? "#f4f4f4" : "transparent",
      "&:hover": {
        backgroundColor: "#f4f4f4",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorContainer: provided => ({
      ...provided,
      color: "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: "#000",
    }),
    singleValue: provided => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: "block",
      color: "#000",
    }),
  }

  const categories_update = [
    { label: t("Wszystkie"), value: "" },
    ...categories
      ?.filter(item => item.name !== "Uncategorized")
      ?.map(item => ({
        label: item.name,
        value: item.name,
      })),
  ]

  return (
    <section className="news-header">
      <div className="container-fluid">
        <div className="news-header__wrapper">
          <h1>{t("Aktualności")}</h1>
          <div className="select-wrapper">
            <Select
              placeholder={t("Kategoria")}
              styles={selectStyles}
              defaultValue={{
                label: t("Wszystkie"),
                value: "",
              }}
              options={categories_update}
              closeMenuOnSelect
              hideSelectedOptions={false}
              onChange={e => setCat(e.value)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
